
import { defineComponent, toRef, computed } from "vue";
export default defineComponent({
  name: "GalleryCaroussel",
  props: {
    dots: Boolean,
    infinite: Boolean,
    images: Array,
  },
  setup(props) {
    const dots = toRef(props, "dots");
    const infinite = toRef(props, "infinite");
    const bgImage = toRef(props, "images");

    const dotsCondition = computed(() => {
      return dots.value ? dots.value : false;
    });

    const infiniteCondition = computed(() => {
      return infinite.value ? infinite.value : false;
    });

    const bgPosition = computed(() => {
      return bgImage.value
        ? "bgi-no-repeat bgi-position-y-center bgi-position-x-left bgi-size-cover"
        : "";
    });

    return {
      dotsCondition,
      infiniteCondition,
      bgPosition,
      bgImage,
    };
  },
});
