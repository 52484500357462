import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card card-custom" }
const _hoisted_2 = { class: "card-body card-rounded p-0 pb-2 d-flex mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_agile = _resolveComponent("agile")!

  return (_openBlock(), _createBlock(_component_agile, {
    dots: _ctx.dotsCondition,
    infinite: _ctx.infiniteCondition,
    "autoplay-speed": 5000
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
        return (_openBlock(), _createElementBlock("div", {
          key: image.id,
          class: "slide"
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass(["bg-cover-measurements d-md-flex ms-auto bgi-no-repeat bgi-position-y-center bgi-position-x-left bgi-size-cover", _ctx.bgPosition]),
                style: _normalizeStyle({ backgroundImage: 'url(' + image.href + ')' })
              }, null, 6)
            ])
          ])
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["dots", "infinite"]))
}