
import { defineComponent } from "vue";
import Dropdown3 from "@/view/content/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "Widget1",
  components: {
    Dropdown3,
  },
  setup() {
    const bgImage = "media/svg/illustrations/bg-1.svg";
    const statTitle = "250 anos";
    const title = "Sobre a cidade";
    const subtitle =
      "População: 1.409.351 <br />Fonte: IBGE - Censo Demográfico 2010";
    const stats = [
      {
        name: "81 Bairros",
        color: "bg-primary",
      },
      {
        name: "16 Ilhas",
        color: "bg-danger",
      },
      {
        name: "17 Regiões",
        color: "bg-warning",
      },
    ];

    const options = {
      chart: {
        type: "donut",
        fontFamily: "inherit",
      },
      plotOptions: {
        pie: {
          customScale: 0.94,
          expandOnClick: false,
          donut: {
            size: "75%",
            labels: {
              show: false,
            },
          },
        },
      },
      labels: ["81 Bairros", "16 Ilhas", "17 Regiões"],
      colors: ["#ea4455", "#fec703", "#457962"],
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    };

    const series = [33, 33, 33];

    return {
      bgImage,
      statTitle,
      title,
      subtitle,
      stats,
      options,
      series,
    };
  },
});
