
import { defineComponent, toRef, computed } from "vue";

export default defineComponent({
  name: "Widget6",
  props: {
    title: String,
    subtitle: String,
    collection: Array,
  },
  setup(props) {
    const title = toRef(props, "title");
    const titleText = computed(() => {
      return title.value ? title.value : "";
    });

    const subtitle = toRef(props, "subtitle");
    const subtitleText = computed(() => {
      return subtitle.value ? subtitle.value : "";
    });

    const collection = toRef(props, "collection");
    const collectionData = computed(() => {
      return collection.value ? collection.value : "";
    });

    return {
      titleText,
      subtitleText,
      collectionData,
    };
  },
  components: {},
});
