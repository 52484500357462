
import { defineComponent, toRef, computed } from "vue";

export default defineComponent({
  name: "Widget8",
  props: {
    title: String,
    currentActive: Number,
    items: Array,
  },
  components: {},
  setup(props) {
    const collection = toRef(props, "items");

    const collectionData = computed(() => {
      return collection.value ? collection.value : [];
    });

    return {
      collectionData,
    };
  },
  data() {
    return {
      index: this.currentActive,
    };
  },
  methods: {
    setGalleryActive(id) {
      this.index = id;
    },
  },
});
