
import { defineComponent, toRef, computed } from "vue";
import GalleryCaroussel from "@/view/content/gallery/GalleryCaroussel.vue";

export default defineComponent({
  name: "Widget2",
  components: {
    GalleryCaroussel,
  },
  props: {
    title: String,
    subtitle: String,
    urlPath: String,
    toolbarButtonLabel: String,
    collection: Array,
  },
  setup(props) {
    const title = toRef(props, "title");
    const titleText = computed(() => {
      return title.value ? title.value : "";
    });

    const subtitle = toRef(props, "subtitle");
    const subtitleText = computed(() => {
      return subtitle.value ? subtitle.value : "";
    });

    const urlPath = toRef(props, "urlPath");
    const urlPathText = computed(() => {
      return urlPath.value ? urlPath.value : "";
    });

    const toolbarButtonLabel = toRef(props, "toolbarButtonLabel");
    const toolbarButtonLabelText = computed(() => {
      return toolbarButtonLabel.value ? toolbarButtonLabel.value : "Ver Todos";
    });

    const collection = toRef(props, "collection");
    const collectionData = computed(() => {
      return collection.value ? collection.value : "";
    });

    return {
      titleText,
      subtitleText,
      urlPathText,
      toolbarButtonLabelText,
      collectionData,
    };
  },
  data() {
    return {
      currentActive: "1",
    };
  },
  methods: {
    setGalleryActive(id) {
      this.currentActive = id;
    },
  },
});
