import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card card-custom card-stretch" }
const _hoisted_2 = { class: "card-header align-items-center py-5 border-0" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-overflow": "true",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_8 = { class: "svg-icon svg-icon-1" }
const _hoisted_9 = { class: "card-body pt-12" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "d-flex justify-content-around pt-10 row" }
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown3 = _resolveComponent("Dropdown3")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createElementVNode("span", {
          class: "fw-bolder text-dark fs-2",
          innerHTML: _ctx.title
        }, null, 8, _hoisted_4),
        _createElementVNode("span", {
          class: "text-muted mt-2 fw-bold fs-4",
          innerHTML: _ctx.subtitle
        }, null, 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown3)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", {
        class: "d-flex flex-center position-relative bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-center h-175px",
        style: _normalizeStyle(`background-image: url('${_ctx.bgImage}')`)
      }, [
        _createElementVNode("div", {
          class: "fw-bolder fs-1 text-gray-800 position-absolute",
          innerHTML: _ctx.statTitle
        }, null, 8, _hoisted_10),
        false
          ? (_openBlock(), _createBlock(_component_apexchart, {
              key: 0,
              width: "270",
              type: "donut",
              options: _ctx.options
            }, null, 8, ["options"]))
          : _createCommentVNode("", true)
      ], 4),
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stats, (stat) => {
          return (_openBlock(), _createElementBlock("div", {
            key: stat.color,
            class: "col-4 my-3"
          }, [
            _createElementVNode("span", {
              class: "fw-bolder text-gray-800",
              innerHTML: stat.name
            }, null, 8, _hoisted_12),
            _createElementVNode("span", {
              class: _normalizeClass(`${stat.color} w-25px h-5px d-block rounded mt-1`)
            }, null, 2)
          ]))
        }), 128))
      ])
    ])
  ]))
}